import { Container, } from "react-bootstrap";
import termsDoc from "../assets/docs/regulamin.pdf"

const ConditionsOfUse = () =>{    
   
    return (
        <Container>
            <h4>Regulamin</h4>
            <div>
                <a href={termsDoc} target="_blank" rel="noreferrer">Obowiązuje od 2.08.2023</a>
            </div>
        </Container>
    );
}

export  default ConditionsOfUse;