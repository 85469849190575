import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const configFirebase = () => {
    // Your web app's Firebase configuration
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FB_API_KEY,
        authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FB_DB_URL,
        projectId: process.env.REACT_APP_FB_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,
        appId: process.env.REACT_APP_FB_APP_ID
    };
    console.log("firebaseConfig", firebaseConfig);

     // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    if (process.env.NODE_ENV === 'production') {
        //window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
        //console.log('process.env.NODE_ENV:', process.env.NODE_ENV);
        const appCheck = initializeAppCheck(app, {
            provider: new ReCaptchaV3Provider('6LcJDlInAAAAAH5YjXO9cNUMSSw5iouum3Or6Eeu'),
          
            // Optional argument. If true, the SDK automatically refreshes App Check
            // tokens as needed.
            isTokenAutoRefreshEnabled: true
          });
    }

    const db = getFirestore(app);
    return { db };
}
   
export default configFirebase;