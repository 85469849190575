import "bootstrap/dist/css/bootstrap.min.css";
// Import the functions you need from the SDKs you need
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CreatePlace from './pages/CreatePlace';
import CreatePlaceSuccess from "./pages/CreatePlaceSuccess";
import ConditionsOfUse from "./pages/ConditionsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const App = () => {
  const brandName = 'Out4.Fun';
  
  return (
    <Router>
      <div>
        <Header brandName={brandName}/>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/addPlace">
            <CreatePlace />
          </Route>
          <Route exact path="/addPlaceSuccess">
            <CreatePlaceSuccess />
          </Route>
          <Route exact path="/terms">
            <ConditionsOfUse />
          </Route>
          <Route exact path="/privacyPolicy">
            <PrivacyPolicy />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
