import { collection, getDocs } from "firebase/firestore/lite";
import { query, where, orderBy, startAt, endAt } from "firebase/firestore/lite";
import { geohashQueryBounds, distanceBetween } from "geofire-common";



const fetchPlaces = async (db, params) => {
    //console.log('DB: ', db);
    //console.log('position: ' + params.position, 'dist: ' + params.dist, 'roofType: ' + params.roofType, 'paymentType: ' + params.paymentType);

    const radiusInM = params.dist * 1000;

    const bounds = geohashQueryBounds(params.position, radiusInM);

    const places = [];
    for (const b of bounds) {
      //console.log("b[0] - b[1]: " + b[0] + ", " + b[1]);
      let q = query(collection(db, "places"), 
        where("status", "==", params.status),
        orderBy("geohash"), 
        startAt(b[0]),
        endAt(b[1])
      );

      if(params.roofType && params.roofType !== 'ALL'){
       // console.log('params.roofType:', params.roofType);
        q = query(q, where("weatherType", "==", params.roofType));
      }
  
      if(params.paymentType && params.paymentType !== 'ALL'){
       // console.log('params.paymentType:', params.paymentType);
        q = query(q, where("paymentType", "==", params.paymentType));
      }

      const placesSnapshot = await getDocs(q);

      const placesList = placesSnapshot.docs.map((doc) => {
        //console.log('Doc: ' , doc, doc.data());
        return { ...doc.data(), id: doc.id };
      });

      //console.log('Places list in useFetch: ', placesList);
      //console.log('Places list in useFetch: ', placesList[0]);
      for (const place of placesList) {
          const distanceInKm = distanceBetween([place.location._lat, place.location._long], params.position);
          const distanceInM = distanceInKm * 1000;
        //  console.log('distanceInM', distanceInM);
        //  console.log('radiusInM', radiusInM);
          if (distanceInM <= radiusInM) {
            places.push(place);
          }
      }
    }


  return places;
}
 
export default fetchPlaces;