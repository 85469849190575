import { useState, useEffect } from "react";
import { Container, Button, Form, Col, Row, Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LeafletMap from "../components/LeafletMap";
import configFirebase from "../functions/configFirebase";
import { collection, addDoc, GeoPoint, serverTimestamp } from "firebase/firestore/lite";
import { geohashForLocation } from "geofire-common";

const CreatePlace = () =>{    
    let centerOfPoland = [52.217637323491935, 19.353090499179974];
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [roof, setRoof] = useState('SKY');
    const [payment, setPayment] = useState('PAID');
    const [type, setType] = useState('');
    const [position, setPosition] = useState(centerOfPoland);
    const [validated, setValidated] = useState(false);
    const history = useHistory();
    const [globalError, setGlobalError] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setValidated(true);
        const form = event.currentTarget;
         if (form.checkValidity() === false) {
           console.log('inside checkValidity');
           return;
         }
        
        // Add a new document with a generated id.
        addPlace().then(
            (value) => {
                console.log("success: ", value, value.id); 
                setGlobalError(false);
                history.push("/addPlaceSuccess");
            },
            (value) => {
                console.log("error: ", value); 
                setGlobalError(true);
            }
        );      

      };

      const addPlace = async () => {
        const {db} = configFirebase();
        const docRef = await addDoc(collection(db, "places"), {
            name: name,
            url: url,
            weatherType: roof,
            paymentType: payment,
            type: type,
            location: new GeoPoint(position[0], position[1]) ,
            geohash: geohashForLocation(position),
            status: "NEW",
            createdAt: serverTimestamp()
          });
          return docRef;        
      }

      const options = {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 0,
      };
      
      function success(pos) {
        const crd = pos.coords;
      
        console.log("Your current position is:");
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Longitude: ${crd.longitude}`);
        console.log(`More or less ${crd.accuracy} meters.`);
        setPosition([crd.latitude, crd.longitude]);
      }
      
      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }
      
      useEffect(()=>{
        console.log('Home, useEffect');
        navigator.geolocation.getCurrentPosition(success, error, options);
      }, []);

    return (
        <Container>
            <div className="createPlace">
                <h2>Dodaj nowe miejsce</h2>
                <div className="my-2"><a href="https://youtu.be/O88K5Ha9USo" target="_blank">Jak dodać atrakcję do mapy? (otworzy się w nowej zakładce)</a></div>
                {globalError && <Alert variant="danger" onClose={() => setGlobalError(false)} dismissible>
                    Wystąpił błąd podczas dodawania punktu. 
                    </Alert>
                }
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formPlaceTitle">
                        <Form.Label>Nazwa miejsca</Form.Label>
                        <Form.Control 
                            required type="text" 
                            placeholder="Wpisz nazwę miejsca" 
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            />
                        <Form.Text className="text-muted">
                            Wpisz nazwę własną, np. Lemur Park.
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">Pole jest wymagane</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formRoof">
                        <Form.Label>Czy można korzystać w deszczową pogodę ?</Form.Label>
                        <Form.Select aria-label="Default select example"
                            value={roof}
                            onChange={(e) => setRoof(e.target.value)}
                        >
                            <option value="SKY">Nie</option>
                            <option value="ROOF">Tak</option>
                        </Form.Select>
                        <Form.Text className="text-muted">
                            Wybierz NIE jeśli większość atrakcji jest pod gołym niebem, np. jeśli występują wiaty, ale większość atrakcji jest pod gołym niebem to nadal zaznacz NIE :).
                            Zaznacz TAK dla aquaparków, basenów, sal zabaw itp.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPayment">
                        <Form.Label>Czy atrakcja jest płatna ?</Form.Label>
                        <Form.Select aria-label="Default select example"
                            value={payment}
                            onChange={(e) => setPayment(e.target.value)}
                        >
                            <option value="FREE">Nie</option>
                            <option value="PAID">Tak</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPlaceType">
                        <Form.Label>Rodaj atrakcji</Form.Label>
                        <Form.Select required aria-label="Default select example"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value="">Wybierz ...</option>
                            <option value="WATER">Basen/Park wodny</option>
                            <option value="PLAYGROUND">Plac zabaw</option>
                            <option value="PLAY_ROOM">Sala zabaw</option>
                            <option value="AMUSEMENT_PARK">Wesołe miasteczko</option>
                            <option value="ANIMAL">Zoo/minizoo/farma zwierząt</option>
                            <option value="THEME_PARK">Park tematyczny, np. dinopark</option>
                            <option value="EDUCATION">Edukacyjne</option>
                            <option value="OTHER">Inne</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">Wybierz jedną opcję z listy</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formUrl">
                        <Form.Label>Adres WWW (opcjonalnie)</Form.Label>
                        <Form.Control 
                            type="url" 
                            placeholder="Zacznij od https://... " 
                            value={url}    
                            onChange={(e) => setUrl(e.target.value)}                    
                            />
                            <Form.Control.Feedback type="invalid">Zacznij od 'https://'</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group  className="mt-3" controlId="formPositionLat">
                        <Form.Label>Wskaż lokalizację miejsca na mapie. Jeśli udostępniłeś swoje położenie to punkt na mapie wskazuje gdzie teraz jesteś.</Form.Label>
                    </Form.Group>
    
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" className="mb-3" controlId="formPositionLat">
                             <Form.Control 
                                type="text"                                           
                                value={position[0]}
                                onChange={(e) => setPosition([e.target.value, position[1]])}                      
                                />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3" controlId="formPositionLon">
                                <Form.Control 
                                type="text"                    
                                value={position[1]}    
                                onChange={(e) => setPosition([position[0], e.target.value])}                     
                                />
                        </Form.Group>
                    </Row>
                        <LeafletMap 
                            markers={[]}                     
                            center={position} 
                            heightCss="50vh"
                            zoom={7} onClickHandler={(position)=> setPosition(position)} />

                    <Button variant="primary" type="submit" className="mt-3">
                        Dodaj miejsce
                    </Button>
                    </Form>
            </div>
        </Container>
    );
}

export  default CreatePlace;