import { Badge, Button, Stack } from "react-bootstrap";
import { Popup } from "react-leaflet";
import AdsPanel from "./AdsPanel";


const MarkerPopup = ({marker}) => {
  const markersType = new Map();
  markersType.set('ANIMAL', "Zoo/Minizoo itp.");
  markersType.set('EDUCATION', "Edukacyjne");
  markersType.set('AMUSEMENT_PARK', "Wesołe miasteczko");
  markersType.set('PLAYGROUND', "Plac zabaw");
  markersType.set('PLAY_ROOM', "Sala zabaw");
  markersType.set('THEME_PARK', "Park tematyczny, np. dinopark, farma itd.");
  markersType.set('WATER', "Basen/AquaPark/Jezioro itd.");
  markersType.set('OTHER', "Inne");

  return (
    <Popup>     
        <h3>{marker.name}</h3>
        <div className="my-2"><Badge bg="info" text="dark">Typ atrakcji: {markersType.get(marker.type)}</Badge></div>
        <Stack direction="horizontal" gap={1}>
          <Badge bg="warning" text="dark">{marker.paymentType==='PAID'?'Płatna':'Darmowa'}</Badge>
          <Badge className="pl-1" bg="warning" text="dark">{marker.weatherType==='ROOF'?'Zadaszona':'Pod gołym niebem'}</Badge>
        </Stack>
       { marker.url && <Button className="mt-2" href={marker.url} target="_blank" variant="outline-info">Strona www</Button> }
        <AdsPanel />
      </Popup>
  );
}
 
export default MarkerPopup;