import { useState } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";

const Search = ({onChangeCriteria}) => {


    const [dist, setDist] =  useState(50);
    const [roof, setRoof] =  useState("ALL");
    const [payment, setPayment] =  useState("ALL");

    const onChangeDist = (e)=>{
        setDist(e.target.value);
        onChangeCriteria(e.target.value, roof, payment);
    }

    const onChangeRoof = (e)=>{
        setRoof(e.target.value);
        onChangeCriteria(dist, e.target.value, payment);
    }

    const onChangePayment = (e)=>{
        setPayment(e.target.value);
        onChangeCriteria(dist, roof, e.target.value);
    }

    return (
        <Container>   
            <h1 className="text-center mt-4">Gdzie chcesz spędzić czas z dzieckiem?</h1>
            <div><a href="https://youtu.be/pl6ar3YEmJs" target="_blank">Jak wyszukiwać ? (otworzy się w nowej zakładce)</a></div>     
            <Form>
            <InputGroup className="my-3">
                <Form.Control plaintext readOnly defaultValue="Od miejsca wskazanego na mapie:" />
                <Form.Select aria-label="Odległość" 
                    onChange={onChangeDist}
                    value={dist}
                >
                <option value="5">do 5 km</option>
                <option value="25">do 20 km</option>
                <option value="50">do 50 km</option>
                <option value="100">do 100 km</option>
                <option value="200">do 200 km</option>
                <option value="500">do 500 km</option>
                </Form.Select>
                <Form.Select aria-label="Czy zaddaszone?"
                    onChange={onChangeRoof}
                    value={roof}
                >
                <option value="ROOF">pod dachem</option>
                <option value="SKY">pod gołym niebem</option>
                <option value="ALL">wszystkie</option>
                </Form.Select>
                <Form.Select aria-label="Czy płatne"
                    onChange={onChangePayment}
                    value={payment}
                >
                <option value="PAID">płatne</option>
                <option value="FREE">darmowe</option>
                <option value="ALL">wszystkie</option>
                </Form.Select>
            </InputGroup>
            
            </Form>
        </Container>
    );
}
 
export default Search;