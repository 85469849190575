import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo2.jpg";

const Header = ({brandName}) => {
    return (
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand >
          <Link className="nav-link" to="/"><img
              alt=""
              src={logo}
              width="60"
              height="60"
              className="d-inline-block align-top"
            />{' '}
</Link>
            
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" variant="pills">
            <Nav.Item>
              <Link className="nav-link" to="/">Mapa</Link>
            </Nav.Item>
            <Nav.Item>
              <Link className="nav-link active text-white"  to="/addPlace">+ Dodaj nowy </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}
 
export default Header;