import { Container} from "react-bootstrap";
import privacyPolicyDoc from "../assets/docs/politykaPrywatnosci.pdf"

const PrivacyPolicy = () =>{    
   
    return (
        <Container>
            <h4>Polityka prywatności</h4>
            <a href={privacyPolicyDoc} target="_blank" rel="noreferrer">Obowiązuje od 2.08.2023</a>
        </Container>
    );
}

export  default PrivacyPolicy;