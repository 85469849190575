import { useState, useEffect } from "react";
import Search from '../components/Search';
import LeafletMap from '../components/LeafletMap';
import configFirebase from '../functions/configFirebase';
import fetchPlaces from '../functions/fetchPlaces';
import { Container } from "react-bootstrap";

const centerOfPoland = [52.217637323491935, 19.353090499179974];
const Home = () => {
  const {db} = configFirebase();
  const [markers, setMarkers] =  useState([]);
  const [filterCriteria, setFilterCriteria] =  useState({
     dist:50, roofType: 'ALL' , paymentType: 'ALL'
  });

  const [position, setPosition] =  useState(centerOfPoland); 

  useEffect(()=>{
    fetchDataForMarkers(
      position, 
      filterCriteria.dist,
      filterCriteria.roofType,
      filterCriteria.paymentType
    );
  },[position, filterCriteria]);

  const applySearchCriteria = (dist, roofType, paymentType) => {
    setFilterCriteria({
      dist: dist, 
      roofType: roofType, 
      paymentType: paymentType
    });
  };

  const clickOnMapHandler = (position) =>{
    console.log('Klik w mapę', position);
    setPosition(position);
  }

  const fetchDataForMarkers = (position, dist, roofType, paymentType) =>{
    //console.log('fetchDataForMarkers', position);
    fetchPlaces(db , {status: "ACTIVE", position, dist, roofType, paymentType})
      .then(data => {
        console.log('placesList: ' , data);
        setMarkers(data);
      });
  }
   
  const options = {
    enableHighAccuracy: true,
    timeout: 15000,
    maximumAge: 0,
  };
  
  function success(pos) {
    const crd = pos.coords;
  
    // console.log("Your current position is:");
    // console.log(`Latitude : ${crd.latitude}`);
    // console.log(`Longitude: ${crd.longitude}`);
    // console.log(`More or less ${crd.accuracy} meters.`);
    setPosition([crd.latitude, crd.longitude]);
  }
  
  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  
  useEffect(()=>{
    navigator.geolocation.getCurrentPosition(success, error, options);
  }, []);

  return (
    <Container>
      <Search onChangeCriteria={applySearchCriteria} />

      {position  && <LeafletMap markers={markers} center={position}
       zoom={12} onClickHandler={clickOnMapHandler} /> }
    </Container>
  );
}

export default Home;
