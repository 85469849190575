import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from "react-leaflet";
import { Icon } from "leaflet";
import { useEffect, useState } from "react";
import MarkerPopup from "./MarkerPopup";

const LeafletMap = ({markers, center, zoom, onClickHandler, heightCss='80vh'}) => {
  const centerIcon = new Icon({
    iconUrl: require("../assets/icons/start.png"),
    iconSize: [38, 38]
  });

  const animalIcon = new Icon({
    iconUrl: require("../assets/icons/animal.png"),
    iconSize: [38, 38]
  });

  const educationIcon = new Icon({
    iconUrl: require("../assets/icons/education.png"),
    iconSize: [38, 38]
  });

  const lunaparkIcon = new Icon({
    iconUrl: require("../assets/icons/lunapark.png"),
    iconSize: [38, 38]
  });

  const playGroundIcon = new Icon({
    iconUrl: require("../assets/icons/playGround.png"),
    iconSize: [38, 38]
  });

  const playRoomIcon = new Icon({
    iconUrl: require("../assets/icons/playRoom.png"),
    iconSize: [38, 38]
  });

  const themeIcon = new Icon({
    iconUrl: require("../assets/icons/themePark.png"),
    iconSize: [38, 38]
  });

  const waterpoolIcon = new Icon({
    iconUrl: require("../assets/icons/waterPool.png"),
    iconSize: [38, 38]
  });
  const otherIcon = new Icon({
    iconUrl: require("../assets/icons/other.png"),
    iconSize: [38, 38]
  });

  const iconsMap = new Map();
  iconsMap.set('ANIMAL', animalIcon);
  iconsMap.set('EDUCATION', educationIcon);
  iconsMap.set('AMUSEMENT_PARK', lunaparkIcon);
  iconsMap.set('PLAYGROUND', playGroundIcon);
  iconsMap.set('PLAY_ROOM', playRoomIcon);
  iconsMap.set('THEME_PARK', themeIcon);
  iconsMap.set('WATER', waterpoolIcon);
  iconsMap.set('OTHER', otherIcon);

const LocationMarker = () => {
  const [ position, setPosition ] = useState(center)
   
  const map = useMap();

  useEffect(()=>{

    map.flyTo(position, map.getZoom());
  },[]);

 
  useMapEvents({
        dblclick(e) { 
            console.log(
                e.latlng.lat,
                e.latlng.lng
            );
            setPosition([e.latlng.lat, e.latlng.lng]);
            onClickHandler([e.latlng.lat, e.latlng.lng]);                
        },            
    })
 
   return ( 
       <Marker 
         position={position}
         interactive={false} 
         icon={centerIcon}
         >
            <Popup><h2>Od tego miejsca mierzymy odległość. Jest to Twoja przybliżona lokalizacja jeśli została wyrażona zgoda. Kliknij na mapę aby ustawić punkt w inne miejsce.</h2></Popup>
         </Marker>
    )   
  } 
    console.log('center', center);
    return (
      <MapContainer center={center} zoom={zoom} style={{ height: heightCss }}>
        <LocationMarker />    
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {markers.map((marker) => (
          <Marker key={marker.id}
            position={[marker.location._lat, marker.location._long]}
            icon={iconsMap.get(marker.type) === undefined ? otherIcon : iconsMap.get(marker.type)}
          >
            <MarkerPopup marker={marker} />
          </Marker>
        ))}
      </MapContainer>
    );
}
 
export default LeafletMap;