import { Container, Nav, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";


const CreatePlaceSuccess = () =>{    
   
    return (
        <Container>
            <Alert className="mt-5" variant="success">
                Dziękujemy za rejestrację punktu na mapie.<br/>
                Punkt pojawi się na mapie gdy zostanie zatwierdzony przez osbługę.
            </Alert>
            <h4>Co chcesz dalej zrobić ?</h4>
            <Nav className="">
                <Nav.Item>
                <Link className="nav-link" to="/">Poszukać atrakcji dla mojego dziecka na Mapie</Link>
                </Nav.Item>
                <Nav.Item>
                <Link className="nav-link" to="/addPlace">Dodać kolejny punkt na mapie</Link>
                </Nav.Item>
          </Nav>
        </Container>
    );
}

export  default CreatePlaceSuccess;