import { Container} from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <Container>
          <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <ul className="nav justify-content-center">
              <li className="nav-item"><Link to="/terms" className="nav-link px-2 text-body-secondary">Regulamin</Link></li>
              <li className="nav-item"><Link to="/privacyPolicy" className="nav-link px-2 text-body-secondary">Polityka prywatności</Link></li>
            </ul>
            <div>{ process.env.REACT_APP_VERSION} </div>
          </footer>
        </Container>
    );
}
 
export default Footer;