import adsJava1 from '../assets/ads/1.jpg';
import adsJava2 from '../assets/ads/2.jpg';
import adsJava3 from '../assets/ads/3.jpg';
import adsJava4 from '../assets/ads/4.jpg';
import adsJava5 from '../assets/ads/5.jpg';
import adsJava6 from '../assets/ads/6.jpg';
import adsJava7 from '../assets/ads/7.jpg';

const AdsPanel = () => {
    const adsArray = [adsJava1,adsJava2,adsJava3,adsJava4,adsJava5,adsJava6, adsJava7];
    const randomIdx = Math.floor(Math.random() * adsArray.length);
    const adsUrl = "https://to.twoje-kursy.pl/s/kariera-w-it.html?utm_source=out4fun&utm_medium=out4fun&utm_campaign=out4fun&utm_content=ads"+randomIdx + "&utm_term=ads" + randomIdx;
    return (
      <a href={adsUrl} target="_blank" rel="noreferrer">
        <div className="border my-3">
          <p className="m-1 small">Reklama</p>
          <img href={adsUrl} target="_blank"
              alt="popup icon"
              src={adsArray[randomIdx]}
              width="100%"
          />
        </div>
      </a>
    );
}
 
export default AdsPanel;